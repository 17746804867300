import * as React from "react"
import FAQ from "../components/faq/faq"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/faq-style.css"
import { graphql } from "gatsby"
import { organizationSchema } from "../const/schemas"
import { languages } from "../const/languages"

const FAQPage = ({ data }) => <FAQ data={data} lang={languages.EN}/>

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Answers to your questions about AR in business!"
        description="Augmented reality without any secrets. Find out the answers to our customers' most frequently asked questions about virtual try-on, or contact us and ask your own!"
        canonicalURL="https://ar-labs.io/faq"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export const FAQContentQuery = graphql`
  query FAQPageQuery {
    wpPage(title: { eq: "FAQ" }) {
      content
    }
  }
`

export default FAQPage
